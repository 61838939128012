<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title m-0">
          <h3 class="card-label m-0">
            {{ $t('clients_loyalty.clients_loyalty') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
      </div>

      <div class="card-body ">
        <b-card-body class="p-0">
          <div class="form-group row">
            <div class="col-lg-6 mb-5">
              <label>{{ $t('clients_loyalty.credit_types') }}<span class="text-danger">*</span></label>
              <multiselect v-model="credit_type"
                           :placeholder="$t('clients_loyalty.credit_types')"
                           :class="validation && validation.credit_types_id ? 'is-invalid' : ''"
                           label="name"
                           track-by="id"
                           :options="credit_types_list"
                           :multiple="false"
                           :taggable="false"
                           :show-labels="false"
                           :show-no-options="false"
                           :show-no-results="false">
              </multiselect>
              <span v-if="validation && validation.credit_types_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.credit_types_id[0] }}
                            </span>
            </div>


            <div class="col-lg-6 mb-5">
              <label>{{ $t('clients_loyalty.currency') }}<span class="text-danger">*</span></label>
              <select name="" id="currency_id" v-model="data.currency_id" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                <option v-for="row in currencies" :value="row.id" :key="row.id">
                  {{ row.name }}
                </option>
              </select>
              <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.currency_id[0] }}
                                </span>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('clients_loyalty.conversion_factor') }}<span class="text-danger">*</span></label>
              <input type="number" min="0" v-model="data.conversion_factor" class="form-control" :class="validation && validation.conversion_factor ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.conversion_factor" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.conversion_factor[0] }}
                            </span>
            </div>
          </div>

        </b-card-body>

        <div class="card-footer pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6">
              <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";

export default {
  name: "from-clients-loyalty",
  data() {
    return {
      mainRoute: 'loyaltyrules/clients-loyalty',
      mainRouteDependency: 'base/dependency',


      data: {
        credit_types_id: null,
        currency_id: null,
        conversion_factor: null,
      },

      validation: null,
      currencies: [],
      credit_types_list: [],
      credit_type: null,

    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {

    credit_type: function (val) {
      if (val) {
        this.data.credit_types_id = val.id;
      }
    },
  },
  methods: {
    save() {
      this.create();
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },


    getData() {
      ApiService.get(this.mainRoute).then((response) => {
        if (response.data.data) {
          this.data.credit_types_id = response.data.data.credit_types_id;
          this.credit_type = response.data.data.credit_types;
          this.data.currency_id = response.data.data.currency_id;
          this.data.conversion_factor = response.data.data.conversion_factor;
        }
      });
    },

    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },

    getCreditTypes() {
      ApiService.get(this.mainRouteDependency + "/credit_types").then((response) => {
        this.credit_types_list = response.data.data;
      });
    },
    async defaultDataForUser() {
      await ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.clients_loyalty")}]);


    this.getCurrencies();
    this.getCreditTypes();
    let promise = this.defaultDataForUser();
    Promise.all([promise]).then(() => {
      this.getData();
    });
  },
};
</script>


